.roulette-container {
  background-color: black;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.coming-soon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5vh;
  text-align: center;
  font-weight: bold;
  font-family: "Press Start 2P", cursive;
  color: #ff7e00;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
  2px 2px 0 #000;
  letter-spacing: 5px;
}

.char {
  display: inline-block;
}


.back-button {
  font-family: "Press Start 2P", cursive;
  font-size: 1.25rem;
  background-color: #ff6c00;
  border: 2px solid #000;
  color: #000;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  animation: blink 1s linear infinite;
  transition: background-color 0.3s;
  margin-top: 50px;
}

.back-button:hover {
  background-color: #cc5800;
}

.clock-gif {
  width: 30vh; 
  margin-bottom: 50px; 
}