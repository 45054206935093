.welcome-message {
  font-family: "Press Start 2P", cursive;
  font-size: 1.5rem;
  color: #ff6c00;
  text-shadow: 2px 2px 0px #000;
}

.enter-button {
  font-family: "Press Start 2P", cursive;
  font-size: 1.25rem;
  background-color: #ff6c00;
  border: 2px solid #000;
  color: #000;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  animation: blink 1s linear infinite;
  transition: background-color 0.3s;
}

.enter-button:hover {
  background-color: #cc5800;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.all {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  position: relative;
}

.all::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #101010;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  backdrop-filter: blur(4px) brightness(50%);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: center;
  padding: 20px 0;
  position: relative;
}


.message-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-container {
  margin-top: -7rem;
}


.logo-main {
  height: 4.2rem;
  margin-left: center;
  margin-bottom: 50px;
  transform: translateY(-20px);
}



@media screen and (max-width: 500px) {
  
  .welcome-message {
    font-family: "Press Start 2P", cursive;
    font-size: 1.3rem;
    color: #ff6c00;
    text-shadow: 2px 2px 0px #000;
  }
}