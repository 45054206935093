@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
::-webkit-scrollbar {
  display: none;
}
.all-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  position: relative;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px; 
  padding: 0 20px;
  background-color: transparent;
  box-sizing: border-box;
  position: fixed;
  z-index: 2;
  transform: scale(0.8);
  border-radius: 5px;
  margin-top: 4px;
}
.header-child {
  transform: scale(1.25);
}
.white-line {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 0;
}

.white-line-inner {
  width: 80%;
  height: 5px;
  border-top: 5px solid black;
  background-color: white;
  border-left: 5px solid black;
  border-right: 5px solid black;
  border-bottom: 5px solid black;
  border-radius: 10px;
}



