.faq-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
}

.faq-title-box {
  width: auto;
  padding: 0px 10px;
  background-color: #fff;
  border-radius: 20px;
  border: 2px solid black;
  position: relative;
  margin-bottom: 40px;
}

.faq-title {
  font-size: 2.5em;
  font-weight: bold;
  font-family: "Press Start 2P", cursive;
  color: #ff7e00;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
    2px 2px 0 #000;
  margin: 10px;
}

.faq-item {
  display: flex;
  justify-content: space-between;
  width: 120vh;
  height: 100px;
  background-color: #fff;
  border-radius: 20px;
  border: 5px solid black;
  margin-bottom: 50px;
  padding: 0 20px;
  box-sizing: border-box;
  align-items: center;
}

.faq-question {
  font-size: 2vh;
  font-family: "Press Start 2P", cursive;
}

.faq-symbol {
  font-size: 4vh;
  font-family: "Press Start 2P", cursive;
}

.faq-item:hover {
  border: 5px solid #ff7e00;
  color: #ff7e00;
}

.faq-item:hover .faq-symbol {
  color: #ff7e00 !important;
}

.faq-question:hover {
  border-color: #ff7e00;
  color: #ff7e00;
}

.faq-answer {
  visibility: hidden;
  max-width: 450px;
}

.faq-answer-open {
  margin-bottom: 50px;
  margin-top: 20px;
  font-size: 1.2rem;
  font-family: Arial, sans-serif;
  visibility: visible;
  background-color: #fff;
  border-radius: 20px;
  border: 5px solid black;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  max-width: 120vh;
}

.faq-answer-content {
  font-size: 1.2em;
  line-height: 1.5rem;
  text-align: start;
}

#fourth-background {
  width: 100%;
  height: 100%;
  background-color: #668a5a;
  position: relative;
  z-index: 0;
}

.footer-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.footer-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
  margin-bottom: 20px;
  flex-direction: row;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.logo-footer {
  width: 2.4em;
  height: 2.4em;
  cursor: pointer;
  margin-right: 20px;
}

.social-icons-footer-child {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.social-icon {
  width: 5em;
  height: 2.6em;
  margin-top: 10px;
}

.faq-answer {
  visibility: hidden;
  max-width: 450px;
}

.faq-answer-open {
  margin-bottom: 50px;
  margin-top: 20px;
  font-size: 1.2rem;
  font-family: Arial, sans-serif;
  visibility: visible;
  background-color: #fff;
  border-radius: 20px;
  border: 5px solid black;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  max-width: 120vh;
}

.faq-answer-content {
  font-size: 1.2em;
  line-height: 1.5rem;
  text-align: start;
}

.footer-text-owner {
  position: absolute;
  bottom: 0;
  right: 20px;
  margin: 0 20px 20px 0;
  font-size: 0.8rem;
  color: white;
  font-family: "Press Start 2P", cursive;
}

@media screen and (max-width: 1600px) {
  .faq-item {
    margin-bottom: 3%;
    width: 100vh;
  }

  .faq-answer-open {
    width: 100vh;
  }

  .faq-title {
    font-size: 2.2em;
    margin: 10px;
  }

  .faq-question {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  .footer-logos {
    margin-top: 250px;
    margin-bottom: 0;
  }

  .footer-text-owner {
    position: static;
    text-align: center;
    margin-top: 20px;
  }
}

@media screen and (max-width: 1100px) {
  .faq-item {
    width: 80vh;
    margin-bottom: 3%;
  }

  .faq-answer-open {
    width: 80vh;
  }

  .faq-title {
    font-size: 2.2em;
    margin: 10px;
  }

  .faq-question {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  .footer-text-owner {
    position: static;
    text-align: center;
    margin-top: 20px;
  }
}

@media screen and (max-width: 900px) {
  .faq-item {
    width: 50vh;
    margin-bottom: 3%;
  }

  .faq-answer-open {
    width: 50vh;
  }

  .faq-title {
    font-size: 2.2em;
    margin: 10px;
  }

  .faq-question {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  .footer-text-owner {
    position: static;
    text-align: center;
    margin-top: 20px;
  }
}

@media screen and (max-width: 600px) {
  .faq-item {
    width: 50vh;
    margin-bottom: 3%;
  }

  .faq-answer-open {
    width: 50vh;
  }

  .faq-title {
    font-size: 2.2em;
    margin: 10px;
  }

  .faq-question {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  .footer-text-owner {
    position: static;
    text-align: center;
    margin-top: 20px;
  }
}

@media screen and (max-width: 1000px) {
  .faq-question {
    font-size: 2vh;
    font-family: "Press Start 2P", cursive;
  }

  .faq-symbol {
    font-size: 3vh;
    font-family: "Press Start 2P", cursive;
  }
}

@media screen and (max-height: 800px) {
  .faq-question {
    font-size: 2.3vh;
    font-family: "Press Start 2P", cursive;
  }
}

@media screen and (max-height: 500px) {
  .faq-question {
    font-size: 2vh;
    font-family: "Press Start 2P", cursive;
  }

  .faq-symbol {
    font-size: 3vh;
    font-family: "Press Start 2P", cursive;
  }
}
