#third-background {
  width: 100%;
  height: 100vh;
  background-color: #668a5a;
  position: relative;
  z-index: 0;
}

.collection-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: calc(108vh - 170px);
  position: absolute;
  left: 0;
}

.collection-title-box {
  width: auto;
  padding: 0px 5px;
  background-color: #fff;
  border-radius: 20px;
  border: 2px solid black;
  position: relative;
  margin-top: 55px;
}

.collection-title {
  font-size: 2.5em;
  font-weight: bold;
  font-family: "Press Start 2P", cursive;
  color: #ff7e00;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
    2px 2px 0 #000;
  margin: 10px;
}

.collection-box {
  width: calc(100% - 170px);
  height: 90%;
  border-radius: 20px;
  border: 2px solid black;
  background-color: #fff;
  position: relative;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.collection-box-content {
  width: 80%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.collection-text {
  font-size: 2.5vh;
  line-height: 4vh;
  text-align: start;
  font-weight: bold;
  font-family: Arial, sans-serif;
  color: black;
  position: relative;
  top: -5%;
  left: 50%;
  transform: translateX(-50%);
}

.artwork-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 1px;
  width: 45%;
  height: 90%;
  position: absolute;
  right: 0;
  padding: 75px;
}

.artwork {
  width: 25vh;
  height: 25vh;
  border: 3px solid black;
  box-sizing: border-box;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: -6px 0 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
  cursor: pointer;
  margin-left: 5vh;
}

.artwork:hover {
  transform: scale(1.2);
  z-index: 1;
}

.new-box,
.art-title,
.arrow-buttons {
  visibility: hidden;
}

@media screen and (max-width: 1850px) {
  .artwork-container {
    padding: 55px;
  }

  .artwork {
    width: 22vh;
    height: 22vh;
  }

  .collection-title {
    font-size: 2em;
  }

  .collection-text {
    font-size: 2.2;
    line-height: 3.5vh;
  }

  .collection-box {
    height: 82;
    width: 80%;
  }
}

@media screen and (max-width: 1650px) {
  .artwork {
    width: 21vh;
    height: 21vh;
  }

  .collection-text {
    font-size: 2vh;
    line-height: 3.5vh;
  }
}

@media screen and (max-width: 1550px) {
  .artwork {
    width: 20vh;
    height: 21vh;
  }

  .collection-text {
    font-size: 2.3vh;
    line-height: 3.5vh;
  }
}

@media screen and (max-width: 1400px) {
  .art-title-box {
    width: 40vh;
    margin: -5vh auto 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -5vh;
    margin-bottom: 15vh;
    padding: 0px 5px;
    background-color: #fff;
    border-radius: 20px;
    margin-bottom: 22vh;
    border: 2px solid black;
  }

  .art-title {
    font-size: 3.5vh;
    font-weight: bold;
    font-family: "Press Start 2P", cursive;
    color: #ff7e00;
    text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
      2px 2px 0 #000;
    margin: 20px;
    margin-left: 2px;
    margin-right: 2px;
    visibility: visible;
  }

  .collection-container {
    width: 100%;
    position: static;
    padding: 0;
  }

  .artwork-container {
    display: none;
  }

  .new-box-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: -20vh;
  }

  .new-box {
    width: 60vh;
    height: 60vh;
    border: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    visibility: visible;
    border-radius: 20px;
    border: 4px solid black;
  }

  .new-box img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 18px;
  }

  .arrow-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -5vh;
    margin-bottom: 15vh;
    padding: 0px 5px;
    background-color: #fff;
    border-radius: 20px;
    border: 2px solid black;
    visibility: visible;
  }

  .arrow-button {
    font-size: 1.8em;
    font-weight: bold;
    font-family: "Press Start 2P", cursive;
    color: #ff7e00;
    text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
      2px 2px 0 #000;
    margin: 10px;
    cursor: pointer;
  }

  .arrow-button-line {
    font-size: 1.8em;
    font-weight: bold;
    font-family: "Press Start 2P", cursive;
    color: #ff7e00;
    text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
      2px 2px 0 #000;
    margin: 10px;
  }

  .arrow-button:hover {
    color: #fff;
  }

  .collection-box {
    width: 90%;
    height: 50vh;
    margin-bottom: 20px;
  }

  #third-background {
    height: auto;
  }
}

@media screen and (max-width: 1000px) {
  .collection-text {
    font-size: 1.8vh;
    line-height: 3vh;
  }
}

@media screen and (max-width: 800px) {
  .collection-text {
    font-size: 1.6vh;
    line-height: 3vh;
  }
}

@media screen and (max-width: 600px) {
  .new-box {
    width: 50vh;
    height: 50vh;
    border: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    visibility: visible;
    border-radius: 20px;
    border: 4px solid black;
  }

  .new-box img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 18px;
  }
}

@media screen and (max-height: 600px) {
  .collection-box {
    width: 70%;
    height: 50vh;
    margin-bottom: 50px;
  }
}
