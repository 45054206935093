.first-background {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url("../public/image.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center; 
  z-index: 1;
}

.title-container {
  right: 20px; 
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}

.title {
  font-size: 4em;
  color: #ff7e00;
  font-family: "Press Start 2P", cursive;
  font-weight: bold;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
    2px 2px 0 #000;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  -webkit-font-smoothing: antialiased;
}

.title::before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.title-logo {
  display: inline-block;
  vertical-align: middle;
  width: 6em;
  height: 6em;
  margin-left: 2vh;
  margin-bottom: 2vh;
}

.continuation {
  width: 100%;
  height: 110vh;
  background: linear-gradient(to bottom, #7fac71, #668a5a);
  position: relative;
  z-index: 0;
}

.nav-link {
  font-family: "Press Start 2P", cursive;
  font-size: 1.4rem;
  color: #df7126;
  cursor: pointer;
  text-decoration: none;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  margin-right: 10vh;
}

.logo {
  width: 2.4em;
  height: 2.4em;
  margin-bottom: 5px;
  cursor: pointer;
  margin-right: 3vh;
}

nav {
  display: flex;
}


.nav-link:hover {
  color: white; 
}


.social-icons {
  display: flex;
  margin-bottom: 4px;
}

.social-icon {
  width: 5rem;
  height: 2.5rem;
}

.mobile-menu {
  display: none;
}

.main-header {
  transition: background-color 0.3s, border 0.1s;
}

.main-header.scrolling {
  background-color: rgba(242, 250, 254, 0.8);
  border: 5px solid black;
}

@media screen and (max-width: 1200px) {
  .logo {
    margin-left: -6vh;
    width: 4em;
    height: 4em;
    margin-top: 5vh;
  }

  .main-header.scrolling {
    background-color: transparent;
    border: none;
  }

  .menu-icon {
    display: block;
  }

  .mobile-menu {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 1);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  .mobile-menu.open {
    opacity: 1;
    visibility: visible;
  }

  .mobile-menu .close-menu {
    position: absolute;
    top: 5vh;
    right: 10vh;
    font-size: 14vh;
    color: white;
    cursor: pointer;
  }

  .mobile-menu .nav-link {
    font-size: 7vh;
    color: white;
    text-align: left;
    margin-bottom: 1.5em;
    margin-left: 10vh;
  }

  .mobile-menu .nav-link:hover {
    color: #df7126;
  }

  .close-menu:hover {
    color: #df7126;
  }

  nav,
  .social-icons {
    display: none;
  }

  .hamburger-icon {
    margin-right: -6vh;
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 4em;
    height: 3em;
    cursor: pointer;
  }

  .hamburger-line {
    width: 100%;
    height: 8px;
    background-color: #ff7e00;
    border: 1px solid black;
  }

  
}


@media screen and (max-width: 900px) {
  .title-logo {
    display: none;
  }

  .title {
    font-size: 4em;
    margin-left: 2vh;
  }

  .mobile-menu .close-menu {
    font-size: 12vh;
  }

  .mobile-menu .nav-link {
    font-size: 6vh;
  }


}


@media screen and (max-width: 700px) {

  .mobile-menu .close-menu {
    font-size: 10vh;
  }

  .mobile-menu .nav-link {
    font-size: 5vh;
    margin-left: 4vh;
  }


}

@media screen and (max-width: 1920px) and (max-height: 600px) {

  .title-container {
    margin-top: 250px;
  }

}