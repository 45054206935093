
.page-transition {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: fadeInOut 0.5s;
  }
  
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  