.second-background {
  width: 100%;
  min-height: calc(80vh + 20vh);
  background: linear-gradient(to bottom, #7fac71, #668a5a);
  position: relative;
  z-index: 0;
}


.roadmap-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 120px 40px;
}

.roadmap-box {
  width: 25%;
  height: 50vh;
  background-color: #fff;
  border-radius: 20px;
  border: 2px solid black;
  position: relative;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.roadmap-box-content {
  width: 80%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.roadmap-title {
  font-size: 2.3em;
  font-weight: bold;
  font-family: "Press Start 2P", cursive;
  color: #ff7e00;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
    2px 2px 0 #000;
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  border: 2px solid black;
  border-radius: 20px;
  padding: 10px;
  background-color: #fff;
}

.roadmap-title-inter {
  font-size: 2vh;
  font-weight: bold;
  font-family: "Press Start 2P", cursive;
  color: #ff7e00;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
    2px 2px 0 #000;
  position: absolute;
  top: -85px;
  left: 50%;
  transform: translateX(-50%);
  border: 2px solid black;
  border-radius: 20px;
  padding: 10px;
  background-color: #fff;
}

.box-text-roadmap {
  font-size: 2.4vh;
  line-height: 1;
  text-align: start;
  margin: 5%;
  font-weight: bold;
  font-family: Arial, sans-serif;
  color: black;
  position: relative;
  top: -4vh;
  left: 45%;
  transform: translateX(-50%);
}

@media screen and (max-width: 1680px)  {

  .roadmap-title {
    font-size: 2em;
  }
  .roadmap-container {
    padding: 150px 40px;
  }

  .roadmap-box {
    width: 25%;
    height: calc(80vh - 30vh);
  }
  .roadmap-box-content {
    height: 50%;
  }


  .roadmap-title-inter {
    font-size: 1.1em;
  }

  .box-text-roadmap {
    font-size: 2vh;
    line-height: 2vh;
  }
}



@media screen and (max-width: 1128px) {


  .roadmap-container {
    flex-direction: column;
    align-items: center;
  }

  .roadmap-box {
    width: 60%;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .roadmap-box-content {
    height: 80%;
  }

  .roadmap-title {
    font-size: 2em;
  }



  .box-text-roadmap {
    line-height: 1.2em;
  }
}


@media screen and (max-width: 600px) {
  .box-text-roadmap {
    margin-top: 110px;
  }

  .second-background {
    height: auto;
  }


  .roadmap-title-inter {
    margin-right: -10px;
  }
}


@media screen and (max-width: 500px) {
  .box-text-roadmap {
    margin-top: 110px;
  }



  .roadmap-title-inter {
    margin-right: -10px;
  }
}


@media screen and (max-height: 600px){
  .box-text-roadmap {
    font-size: 2.5vh;
    line-height: 3vh;
  }
  

}